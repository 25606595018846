#Header{
    width: 90%;
    height: 100px;
    background: rgba(175, 156, 177, 1.0);
    background: -webkit-radial-gradient(center, rgba(175, 156, 177, 1.0), rgba(169, 171, 177, 1.0));
    background: -moz-radial-gradient(center, rgba(175, 156, 177, 1.0), rgba(169, 171, 177, 1.0));
    background: radial-gradient(ellipse at center, rgba(175, 156, 177, 1.0), rgba(169, 171, 177, 1.0));    
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;   
     
}
.headerClass{
   margin: 20px; 
}
#info{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
}
#typeWrite{
color: aqua;   
text-shadow: 5px 7px 6px #8C728F;
}